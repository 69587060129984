/* eslint-disable no-script-url */
import React, { useEffect, useState } from "react";
import ItemAcordeonArea from "../ItemAcordeonArea/ItemAcordeonArea.jsx";
import store from "../../firebase/firebase.js";
import {GrDocumentPdf} from "react-icons/gr"



function CursoDiplo() {
  
  const [listaCategorias, setListaCategorias] = useState("");

  useEffect(() => {
    store.collection("categorias").onSnapshot((snap) => {
      const documents = [];
      snap.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() });
      });
      setListaCategorias(documents);
    });
  }, []);
  

  return (
    <div>
      <div className="cur-dip">
        <ItemAcordeonArea listaCategorias={listaCategorias[0]} />
      </div>
      <div className="pdfs" style={{textAlign:"center", color:"#f38115"}}>
      {
      // eslint-disable-next-line
      }
      
        <GrDocumentPdf style={{width:"50px"}} size="25px"/>Descarga nuestro catálogo de cursos <a href="https://cdn1.institutolap.com/descarga/ILAP_Catalogo.pdf" target="_blank" rel="noreferrer" >aquí</a>
      </div>
      <div>
        
        <div
          className="svg-wave"
          style={{ height: "150px", overflow: "hidden", background: "#eeeaea" }}
        >
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{ height: "100%", width: "100%" }}
          >
            <path
              d="M0.00,49.98 C149.99,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
              style={{ stroke: "none", fill: "white" }}
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
}

export default CursoDiplo;
