import React, { useState, useEffect } from "react";
//import } store from "../../firebase/firebase.js";
import { Accordion } from "react-bootstrap";
import store from "../../firebase/firebase.js";
import TablaCursos from "../TablaCursos/TablaCursos.jsx";

export const ItemAcordeonArea = () => {

  const [listaCategorias, setListaCategorias] = useState([]);

  useEffect(() => {
    store.collection("categorias").onSnapshot((snap) => {
      const documents = [];
      snap.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() });
      });
      setListaCategorias(documents);
    });
  }, []);

  return (
    <div className="contenedor-cursos" id="cursos-id">
      <h3 className="titulo">TE OFRECEMOS:</h3>
      <Accordion flush style={{marginLeft:"20px"}}>
        {listaCategorias &&
          listaCategorias.map((a, i) => {
            return (
              <Accordion.Item eventKey={i}>
                <Accordion.Header>{a.nombre}</Accordion.Header>
                <Accordion.Body >
                  <TablaCursos idarea={a.idarea} />
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
      </Accordion>
    </div>
  );
};

export default ItemAcordeonArea;

